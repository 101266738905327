import { FormControl, MenuItem, Select, Button, TextField, Input, CircularProgress, } from "@material-ui/core";
import { ArrowBack, Filter } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { ExcelDownloaderAPI, fetchEndPoint, fetchMasterTrainer, fetchUserInfo, pagination } from "../../../services/auth";
import CustomTable from "../../partials/table";
import "./styles.scss";
import _ from "lodash";
import Download from "./Download";
import moment from "moment";
import useHash from "./useHash";
import BatchWise from "../../batchWise";
import { useClientContext } from "../../../store/ClientProvider";
import { AppStateContext } from "../../../providers/app.provider";
import ExcelDownloader from "../excelDownloader/excelDownloader";

const ViewDetails = ({ appState, setShow, url, pageConfig, setReport, report, ud }) => {
  const [collectionname, _setCollectionname] = useState(pageConfig?.collectionname);
  const [state] = useContext(AppStateContext);
  const [view, setView] = useState(appState?.view || 'detail')
  let { showBatchAnalytics, newApiEndPoint, paramsForReport, toogleTableDetail, actionableLinks, datakey, } = pageConfig || [];
  let ignoreColumns = pageConfig?.ignoreColumns || [];
  const history = useHistory();
  const { client } = useClientContext();

  const tableRef = useRef();
  const moduleWise = pageConfig?.moduleWise;

  const [whatToShow, setWhatToShow] = useState(false);
  const [page, setPage] = useState(0);
  const [_heading, setHeading] = useState("");

  const [summary, setSummary] = useState([]);
  const [headCells, setHeadCells] = useState([]);

  const [zone, setZone] = useState("");
  const [zones, setZones] = useState([]);

  const [division, setDivision] = useState("");
  const [divisions, setDivisions] = useState([]);

  const [week, setWeek] = useState("");
  const [weeks, setWeeks] = useState([]);

  const [college, setCollege] = useState("");
  const [colleges, setColleges] = useState([]);

  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);

  const [faculty, setFaculty] = useState("");
  const [facultys, setFacultys] = useState([]);

  const [batch, setBatch] = useState("");
  const [batches, setBatches] = useState([]);

  const [certification, setCertification] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [policeStation, setPoliceStation] = useState("");
  const [policeStations, setPoliceStations] = useState([]);
  const [userType, setUserType] = useState("");
  const [userTypes, setUserTypes] = useState([]);
  const [superTrainer, setSuperTrainer] = useState("");
  const [superTrainers, setSuperTrainers] = useState([]);

  const [trianerText, setTrianerText] = useState("");
  const [trainers, setTrainers] = useState([]);

  const [trainer, setTrainer] = useState("");
  const [moduleText, setModuleText] = useState("");
  const [modules, setModules] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const [startDate, setStartDate] = useState();

  const [endDate, setEndDate] = useState();
  const [accesscode, setAccesscode] = useState();
  const [collegename, setCollegename] = useState();
  // const [report, setReport] = useState(pageConfig?.report);


  const [parentFilter, setParentFilter] = useState("");

  const [childFilter, setChildFilter] = useState("");
  const [comboFilter, setComboFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const [hash, _setHash] = useHash();
  const [data, setData] = useState([]);

  const [dynamicFilter, setDynamicFilter] = useState(null)
  const [dataLength, setDataLength] = useState(null)

  const [exteriorFilter, setExteriorFilter] = useState({})
  const organizationName = pageConfig?.organisationName;

  const [toogleTableData, setToogleTableData] = useState(null)
  const [currentView, setCurrentView] = useState(Object.keys(toogleTableDetail || {})[0] || '')

  const tableView = actionableLinks?.length > 0 && actionableLinks.filter(_v => _v.view == view).map(_v => _v)[0]
  let pageNation = pageConfig?.pageNation;

  const params = { ...state?.parentData?.data, view: 'detail' }


  const getUserInfo = async () => {
    return fetchUserInfo().then((data) => {
      setAccesscode(data.data.accesscode);
      let col = data.data.meta?.bput?.college_code?.split(/[,]+/);
      let newCol = col?.[0]?.replace(/ /g, "+");
      setCollegename(newCol);

    });
  };

  useEffect(async () => {
    if (organizationName == "BPUT") {
      await getUserInfo();
    }
  }, []);

  useEffect(() => {
    setShow(false);
    if (organizationName == "BPUT" && collegename) {
      if (hash && collegename && accesscode) {
        setHeading(hash);
        const payload = {
          period: "This Week",
          view: view || "detail",
          collectionname,
          accesscode,
          datakey,
          collegename,
          report,
          client: client,
        };

        getMasterTrainer(payload, url);
      }
    }
    else {
      if (hash && url && url.includes(pageConfig?.api) && !pageNation) {
        setHeading(hash);
        const payload = {
          period: "This Week",
          view: view || "detail",
          collectionname,
          accesscode,
          datakey,
          collegename,
          report: pageConfig?.report,
          client: client,
        };
        getMasterTrainer(payload, url);
      }
    }
  }, [hash, collegename, accesscode, url, pageNation]);

  const user = JSON.parse(localStorage.getItem("user"));

  const splitKey = (key) => {
    const _key = (user.organizationid = "6123add4dfd0fbe63095d7c7"
      ? key.split("  ")
      : key.split("("));

    const keys = {
      id: _key[0],
      label: _key[0].trim(),
      caption: _key[1] ? "(".concat(_key[1]) : null,
    };

    return keys;
  };

  // const processHead = (arr, sum) => {
  //   let arr2 = Object.entries(sum).map(([key, val]) => {
  //     return Object.assign(
  //       {
  //         originalKey: key,
  //         numeric: isNaN(val),
  //         disablePadding: false,
  //       },
  //       splitKey(key)
  //     );
  //   });
  //   if (arr != "arr") setHeadCells(arr2);
  //   return arr2;
  // };

  const processHead = (arr, sum) => {
    let arr2 = [];
    if (Object.keys(sum || {}).length > 0) {
      const heads1 = [sum].map((k) => {
        let arr1 = [];
        for (let i = 0; i < Object.keys(k).length; i++) {
          arr1 = Object.assign(
            {
              originalKey: Object.keys(k)[i],
              numeric: typeof (Object.values(k)[i]) == 'string' ? true : isNaN(Object.values(k)[i]),
              disablePadding: false,
            },
            splitKey(Object.keys(k)[i])
          );
          arr2.push(arr1);
        }
      });
      if (arr != 'arr')
        setHeadCells(arr2);
      return arr2;
    }
  };

  const getUpdatedData = (pageSize) => {
    pagination(
      'https://api.pedgog.in/v1/analytics/pagenationApi/data',
      // 'http://127.0.0.1:8000/v1/analytics/pagenationApi/data',
      { view: "detail", pageSize, sort: 1, collectionname },
      JSON.stringify(dynamicFilter || {})
    )
      .then(data => {
        let _summary = data.data.data
        setSummary(_summary);
        setDataLength(data.data?.count || 1000)
        setExteriorFilter({ ...data?.data?.tableFilters })
        _summary?.[0] && processHead(Object.keys(_summary[0]), _summary[0]);
        // setData(_summary)
      })
  }

  useEffect(() => {
    if (pageNation && (dynamicFilter)) {
      let pageSize = Object.keys(dynamicFilter || {}).length > 0 ? 25 : 1
      getUpdatedData(pageSize);
      setPage(0)
    }
  }, [dynamicFilter])

  useEffect(() => {
    if (((page * 100) == summary?.length || (page * 100) - 100 == summary?.length) && (page % 10 == 0) && pageNation) {
      let pageSize = page == 0 ? 1 : (page / 10) + 1
      getUpdatedData(pageSize);
    }
  }, [page])

  const getMasterTrainer = (payload, url) => {
    if (url && pageConfig) {
      newApiEndPoint ?
        fetchEndPoint(`${newApiEndPoint}`, params)
          .then((res) => {
            let data = { data: { data: res?.data } }
            setData(data.data.data);
            setDynamicFilter({})
            let _summary = data.data.data;
            const _zones = _.uniq(_.map(_summary, (_s) => _s.Zone));
            const _divisions = _.uniq(_.map(_summary, (_s) => _s.Division));

            const _weeks = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.Week)),
              (w) => w
            );
            const _batches = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.Batch)),
              (b) => b
            );
            const _certifications = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Certified"])),
              (c) => c
            );
            const _facultys = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Faculty Name"])),
              (f) => f
            );
            const _regions = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Region"])),
              (f) => f
            );
            const _division1 = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Division"])),
              (f) => f
            );
            const _colleges = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.College)),
              (d) => d
            );
            const _districts = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["District/Units"])),
              (d) => d
            );

            const _policeStation = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Police Station"])),
              (d) => d
            );
            const _superTrainer = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Certification status"])),
              (d) => d
            );

            const _state = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.State)),
              (d) => d
            );

            const _userType = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["User Type"])),
              (d) => d
            );
            const _trainer = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.Trainer)),
              (d) => d
            );
            const _module = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Module"])),
              (d) => d
            );
            setModules(_module);
            setTrainers(_trainer);
            setUserTypes(_userType);
            setZones(_zones);
            setDivisions(_division1);

            setColleges(_colleges);
            setRegions(_regions);
            setFacultys(_facultys);

            setWeeks(_weeks);
            setBatches(_batches);
            setCertifications(_certifications);

            setPoliceStations(_policeStation);

            setSuperTrainers(_superTrainer);

            if (pageConfig?.dateFilter)
              _summary = _summary.map((_s) => ({
                ..._s,
                Date: _s?.Date && moment(_s?.Date).format("DD-MMM-YYYY"),
              }));
            setSummary(_summary);
            _summary?.[0] && processHead(Object.keys(_summary[0]), _summary[0]);
          })
          .catch((err) => {
            setWhatToShow(true);
            console.error(err);
          })
        : fetchMasterTrainer(payload, (pageConfig?.newUrl || url))
          .then((data) => {
            setData(data.data.data);
            setDynamicFilter({})
            let _summary = data.data.data;
            const _zones = _.uniq(_.map(_summary, (_s) => _s.Zone));
            const _divisions = _.uniq(_.map(_summary, (_s) => _s.Division));

            const _weeks = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.Week)),
              (w) => w
            );
            const _batches = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.Batch)),
              (b) => b
            );
            const _certifications = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Certified"])),
              (c) => c
            );
            const _facultys = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Faculty Name"])),
              (f) => f
            );
            const _regions = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Region"])),
              (f) => f
            );
            const _division1 = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Division"])),
              (f) => f
            );
            const _colleges = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.College)),
              (d) => d
            );
            const _districts = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["District/Units"])),
              (d) => d
            );

            const _policeStation = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Police Station"])),
              (d) => d
            );
            const _superTrainer = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Certification status"])),
              (d) => d
            );

            const _state = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.State)),
              (d) => d
            );

            const _userType = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["User Type"])),
              (d) => d
            );
            const _trainer = _.filter(
              _.uniq(_.map(_summary, (_s) => _s.Trainer)),
              (d) => d
            );
            const _module = _.filter(
              _.uniq(_.map(_summary, (_s) => _s["Module"])),
              (d) => d
            );
            setModules(_module);
            setTrainers(_trainer);
            setUserTypes(_userType);

            setZones(_zones);
            setDivisions(_division1);

            setColleges(_colleges);
            setRegions(_regions);
            setFacultys(_facultys);

            setWeeks(_weeks);
            setBatches(_batches);
            setCertifications(_certifications);

            setPoliceStations(_policeStation);

            setSuperTrainers(_superTrainer);

            if (pageConfig?.dateFilter)
              _summary = _summary.map((_s) => ({
                ..._s,
                Date: _s?.Date && moment(_s?.Date).format("DD-MMM-YYYY"),
              }));
            setSummary(_summary);
            _summary?.[0] && processHead(Object.keys(_summary[0]), _summary[0]);
          })
          .catch((err) => {
            setWhatToShow(true);
            console.error(err);
          });
    }
  };


  const handleSearchTextChange = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm)
  };

  const handleClose = () => {
    history.goBack();
  };

  function getDataFromModuleWise(data) {
    const _dataArray = data;

    const optionsByQuestion = {};

    _dataArray?.forEach((item) => {
      const questionNo = item["q_no"];
      const option = item["Options"];
      const participants = item["No of participants choosing the option"];
      const average = item["% of participants choosing the option"];
      const module = item["Module"];
      const avgScore = item["Avg Score for this module"];
      const questionTag = item["Question Tag"];
      const questionNum = item["Question"];

      if (!optionsByQuestion[questionNo]) {
        optionsByQuestion[questionNo] = {
          options: [],
          participants: [],
          average: [],
          module: module,
          avgScore: avgScore?.toString(),
          questionTag: questionTag,
          questionNum: questionNum,
        };
      }

      optionsByQuestion[questionNo].options.push(option);
      optionsByQuestion[questionNo].participants.push(participants);
      optionsByQuestion[questionNo].average.push(average);
    });

    // Convert the object to an array
    const optionsArray = Object.entries(optionsByQuestion).map(
      ([questionNo, data]) => ({
        questionNo,
        Options: data.options,
        "No of participants choosing the option": data.participants,
        "% of participants choosing the option": data.average,
        Module: data.module,
        "Avg Score for this module": data.avgScore,
        "Question Tag": data.questionTag,
        Question: data.questionNum,
      })
    );
    return optionsArray;

    // setDataArray(optionsArray);
  }
  const moduleWisedata = getDataFromModuleWise(data);

  const dateFilter = (row, date, operation) => {
    if (date) {
      if (operation == ">" && moment(row.Date) >= moment(date)) return true;
      else if (operation == "<" && moment(row.Date) <= moment(date))
        return true;
      else return false;
    } else return true;
  };

  const filter = (rows, obj = {}) => {
    let _filter = {
      Zone: zone === "" ? undefined : zone,
      Division: division === "" ? undefined : division,
      Region: region == "" ? undefined : region,
      College: college === "" ? undefined : college,
      ["Faculty Name"]: faculty === "" ? undefined : faculty,
      Batch: batch === "" ? undefined : batch,
      ["Module"]: moduleText === "" ? undefined : moduleText,

      Week: week === "" ? undefined : week,
      Certified: certification === "" ? undefined : certification,

      ["Police Station"]: policeStation === "" ? undefined : policeStation,
      ["User Type"]: userType === "" ? undefined : userType,

      ["Certification status"]: superTrainer === "" ? undefined : superTrainer,

      [comboFilter]: trainer === "" ? undefined : trainer,

      Trainer: trianerText === "" ? undefined : trianerText,

      [pageConfig?.comCouFilter?.parentFilter ||
        pageConfig?.coupleFilter?.parentFilter?.column]:
        parentFilter === "" ? undefined : parentFilter,
      [pageConfig?.coupleFilter?.childFilter?.column]:
        childFilter === "" ? undefined : childFilter,
    };
    _filter = { ..._filter, ...obj };

    _filter = _.omitBy(_filter, _.isUndefined);
    const filteredRows = _.filter(rows, _filter)
      .filter((r) => dateFilter(r, startDate, ">"))
      .filter((r) => dateFilter(r, endDate, "<"));
    return filteredRows;
  };


  function toogleReturnRows(arr, obj) {
    function _searchText(arr) {
      if (searchText) {
        let firstRow = Object.keys(arr?.[0] || {});
        if (arr?.[0]) {
          const searchResults = arr?.filter((row) => {
            for (const field of firstRow) {
              if (row?.[field]) {
                let fd = row?.[field]?.toString()
                if (fd?.toLowerCase()?.includes(searchText?.toLowerCase())) {
                  return true;
                }
              }
            }
            return false;
          });
          return searchResults;
        }
      }
      else {
        return arr;
      }
    }

    function filterArrayByObject(arr, filterObj) {
      if (arr?.length > 0)
        return arr.filter(item => {
          for (const key in filterObj) {
            if (filterObj[key].length > 0) {
              if (key == 'Trainer') {
                if (item['Coach-1 Name'] !== filterObj['Trainer'] && item['Coach-2 Name'] !== filterObj['Trainer']) {
                  return false
                }
              }
              else {
                if (item[key] !== filterObj[key]) {
                  return false;
                }
              }
            }
          }
          return true;
        });

    }
    let filter = obj ? obj : dynamicFilter
    return _searchText(filterArrayByObject(arr, filter))
  }

  function filterKeyValues(obj, n) {
    const filteredPairs = {};
    let count = 0;
    for (const key in obj) {
      if (count < n) {
        filteredPairs[key] = obj[key];
        count++;
      }
    }

    return filteredPairs;
  }

  const getExcel = async () => {
    if (moduleWise) return filter(moduleWisedata) || [];
    else {
      if (Object.keys(dynamicFilter || {}).length == 0 && pageNation) {
        ExcelDownloaderAPI('https://api.pedgog.in/v1/analytics/excelDownload/excel', { view: "detail", pageSize: (summary?.length / 1000) || 10, sort: 1, collectionname }, JSON.stringify(dynamicFilter || {}))
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = 'Excel.xlsx'; // You can specify the desired file name here
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(blobUrl);
          })
      }
      else {
        return toogleReturnRows(summary) || []
      }
    }
  }

  function retFun() {
    if (summary?.length == 0) {
      return <div style={{ display: "flex", position: "fixed", left: "50%", top: '37%', flexGrow: 1, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <CircularProgress />
        <div style={{ margin: "0.5rem", textAlign: "center", color: "#999" }}>Loading...</div>
      </div>
    }
    return (
      <div>
        {showBatchAnalytics && report && summary?.length > 0 ? (
          <BatchWise
            processHead={(a, s) => processHead(a, s)}
            rows={summary}
            setReport={setReport}
            report={report}
            Custom_Table={(data) => {
              return (
                <CustomTable
                  ignoreColumns={["Week", "userId", ...ignoreColumns]}
                  report={report}
                  setReport={setReport}
                  headCells={data.headCells}
                  rows={data.rows}
                  emptyCharacter={""}
                  showColor={pageConfig?.showColor}
                  defaultOrder={"desc"}
                  defaultOrderBy={"Total Participation"}
                  grandTotalColumns={[]}
                  leftAlignColumn={["Super Trainer"]}
                  showRowsPerPage={25}
                  emptyInfo={0}
                />
              );
            }}
          />

        ) : (
          <section className="section view-details" style={{ margin: "0" }}>
            <div
              className="heading-row d-flex"
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div
                className="d-flex"
                style={{ flexDirection: "column", width: "fit-content" }}
              >
                <div className="heading">
                  {tableView?.heading || (pageConfig && pageConfig.heading) || ""}
                </div>
                <div className="sub-heading">
                  {tableView?.subHeading || (pageConfig && pageConfig.subHeading) || ""}
                </div>
              </div>
              <div style={{ top: ud ? '0' : '' }} className="close-btn-l">
                <Button
                  onClick={handleClose}
                  className="close-btn"
                  startIcon={<ArrowBack />}
                >
                  Back
                </Button>
              </div>
              {/* <Download
                rows={moduleWise ? filter(moduleWisedata) || [] : toogleReturnRows(summary) || []}
                filename={pageConfig?.heading || "Sample"}
                headCells={headCells}
                emptyInfo={pageConfig?.emptyInfo || 0}
              /> */}
              <ExcelDownloader
                getExcel={getExcel}
                filename="report.xlsx" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px' }} className="filters-title">
              Filters
              <div style={{ display: "flex", gap: '2rem', fontWeight: 500, color: 'black' }}>
                {Object.keys(pageConfig?.legend || {}).map(col => {
                  return <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ background: col, width: "24px", height: "24px", borderRadius: "50%", }}></div>
                    <div>{pageConfig?.legend?.[col]}</div>
                  </div>
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#f8f8f8", gap: '3rem'
              }}
            >
              <Input
                placeholder="Search..."
                style={{ margin: "2rem 0 0 2rem", paddingTop: "5px" }}
                value={searchText}
                onChange={handleSearchTextChange}
              />
              <div className="selection">
                {(pageConfig?.FilterDetail || [])
                  .map((fil, index) => {
                    if (fil != 'Trainer') {
                      let ob1 = filterKeyValues(dynamicFilter, index)
                      if (ob1[fil]) { delete ob1[fil] }
                      let revisedRows = toogleReturnRows(summary, ob1);
                      let options = exteriorFilter[fil] || [...new Set((revisedRows || []).filter(el => el?.[fil]?.toString()?.length > 0).map(el => el?.[fil]))]
                      let value = dynamicFilter?.[fil] || pageConfig?.defaultStatesDetail?.[fil] || ''
                      return <div className="control" key={fil}>
                        <div className="s-label">{fil}</div>
                        <div className="item-control">
                          <FormControl className="select-form-control">
                            <Select
                              variant="outlined"
                              value={value}
                              onChange={(e) => {
                                if (index == 0) { setDynamicFilter({ ...pageConfig?.defaultStatesDetail }) }
                                if (e.target.value.length == 0) {
                                  let obj = filterKeyValues(dynamicFilter, index)
                                  if (obj[fil]) delete obj[fil]
                                  setDynamicFilter({ ...pageConfig?.defaultStatesDetail, ...obj })
                                }
                                else {
                                  if (dynamicFilter?.['Trainer']) setDynamicFilter((old) => ({ ...old, 'Trainer': '', [fil]: e.target.value }))
                                  else
                                    setDynamicFilter((old) => ({ ...old, [fil]: e.target.value }))
                                }
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label", }}
                            >
                              {!pageConfig?.defaultStatesDetail?.[fil] && <MenuItem value=''>All</MenuItem>}
                              {options
                                .sort((a, b) => a?.toString()?.localeCompare(b?.toString()))
                                .map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    }
                    else {
                      let revisedRows = toogleReturnRows(summary, filterKeyValues(dynamicFilter, index)) || []
                      let ae = []
                      revisedRows.map(el => {
                        if (el?.['Coach-1 Name']?.length > 0) {
                          ae.push(el?.['Coach-1 Name'])
                        }
                        if (el?.['Coach-2 Name']?.length > 0) {
                          ae.push(el?.['Coach-2 Name'])
                        }
                      })
                      let options = [...new Set(ae)]
                      if (options?.length > 0)
                        return <div className="control" key={fil}>
                          <div className="s-label">{fil}</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={dynamicFilter?.['Trainer'] || ''}
                                onChange={(e) => {
                                  if (e.target.value.length > 0)
                                    setDynamicFilter((old) => ({ ...old, ['Trainer']: e.target.value }))
                                  else {
                                    let obj = filterKeyValues(dynamicFilter, index)
                                    if (obj['Trainer']) { delete obj['Trainer'] }
                                    setDynamicFilter({ ...pageConfig?.defaultStatesDetail, obj })
                                  }
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label", }}
                              >
                                {!pageConfig?.defaultStatesDetail?.[fil] && <MenuItem value=''>All</MenuItem>}
                                {options
                                  .sort((a, b) => {
                                    return a.localeCompare(b);
                                  })
                                  .map((s, index) => {
                                    return (
                                      <MenuItem key={index} value={s}>
                                        {s}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                    }
                  })}
              </div>
            </div>
            {
              (zone || division) && (
                <div className="caption">
                  {`${division ? `${division} division` : ""}`}
                </div>
              )
            }
            {toogleTableDetail ? Object.keys(toogleTableData || {}).length > 0 &&
              <CustomTable
                headCells={processHead('arr', toogleReturnRows(toogleTableData?.[currentView])?.[0])}
                rows={toogleReturnRows(toogleTableData?.[currentView])}
                ignoreColumns={["Week", "userId", "report", "q_no", "Program", ...ignoreColumns]}
                ref={tableRef}
                id="table"
                report={report}
                setReport={setReport}
                emptyColumn={pageConfig?.emptyColumn}
                emptyCharacter={pageConfig?.emptyCharacter || ""}
                percentageColumn={pageConfig?.percentageColumn}
                clickable={["Full Name"]}
                open={showDialog}
                dataLength={dataLength}
                page={page}
                setPage={setPage}
                showRowsPerPage={25}
                setOpen={setShowDialog}
                grandTotalColumns={pageConfig?.grandTotalColumns || []}
                showColor={pageConfig?.showColor}
                boldColumn={pageConfig?.boldColumn}
                disableSortColumn={pageConfig?.disableSortColumn}
                columnWidth={pageConfig?.columnWidth}
                emptyInfo={pageConfig?.emptyInfo || 0}
              /> :
              <CustomTable
                rows={moduleWise ? filter(moduleWisedata) : toogleReturnRows(summary)}
                headCells={headCells}
                ignoreColumns={["Week", "userId", "report", "q_no", "Program", ...ignoreColumns]}
                ref={tableRef}
                page={page}
                setPage={setPage}
                dataLength={dataLength}
                id="table1"
                report={report}
                setReport={setReport}
                emptyColumn={pageConfig?.emptyColumn}
                emptyCharacter={pageConfig?.emptyCharacter || ""}
                percentageColumn={pageConfig?.percentageColumn}
                clickable={["Full Name"]}
                open={showDialog}
                setOpen={setShowDialog}
                showRowsPerPage={25}
                grandTotalColumns={pageConfig?.grandTotalColumns || []}
                showColor={pageConfig?.showColor}
                boldColumn={pageConfig?.boldColumn}
                disableSortColumn={pageConfig?.disableSortColumn}
                columnWidth={pageConfig?.columnWidth}
                emptyInfo={pageConfig?.emptyInfo || 0}
              />
            }
          </section >
        )
        }
      </div >
    );
  }

  return <div>{retFun()}</div>;
};

export default ViewDetails;
